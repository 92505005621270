/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

function setVh() {
  const set = () => {
    const vh = window.innerHeight * 0.01
    window.initialHeight = window.innerHeight
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
  set()

  if ("onorientationchange" in window) {
    set()
    window.addEventListener("orientationchange", () => {
      setTimeout(() => set(), 100)
    })
  }
}

exports.onInitialClientRender = () => {
  setVh()
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.prevPath = prevLocation?.pathname
  if (prevLocation?.pathname === "/") {
    window.lastHomePosition =
      window.smooth?.vars?.target || window.pageYOffset || 0
  }
}
